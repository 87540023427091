import React, { PureComponent } from 'react';
import Loadable from 'react-loadable';
import Loading from './AboutComponent';
import {Image, CloudinaryContext} from 'cloudinary-react';
import './New.css';

const ImageLoader = () => {
	return (<CloudinaryContext cloudName="demo">
	<Image
		publicId="https://res.cloudinary.com/pthim/image/upload/v1573615744/np8ocnr6xkd8pjbzpjga.webp"
		fetch-format="auto"
		quality="auto"
		className="loadingGif"
	/>
  </CloudinaryContext>) }

const LoadableComponent = Loadable ({
	loader: () => import('./AboutComponent'),
	loading: Loading,
	delay: 200
});

class About extends PureComponent{
	constructor(props) {
		super(props); 
		this.state = {
			isLoading : true,
			didLoad : false
		}
	  }
	  static getDerivedStateFromProps(props, state) {
		if (props.isrender !== state.isLoading) {
		  return {isLoading: props.isrender }
		}
		// Return null if the state hasn't changed
		return null;
	  }

	  componentDidMount(){
		const { isrender } = this.props
		this.setState({
		  isLoading : isrender
		})
	  }

	onLoad = () => {
		this.setState({
			didLoad : true
		})
	}
  	render() {
	const { isLoading } = this.state
	// const style = this.state.didLoad ? {} : {visibility: 'hidden'}
	
		if(isLoading){
		  return <ImageLoader/>
		}
    	return (
			<div>
				<LoadableComponent/>
			</div>
  		  );
  		}
	}
export default About;