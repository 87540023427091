import React, { PureComponent } from 'react';
import './App.css';
import { Container } from 'react-bootstrap';

class PP extends PureComponent{
  render() {
    return (
      <React.Fragment>

        <div className="backgroundTNC absolute">
          <div className="Header_tnc">
            <Container>
              <h1 className="title_TNC">privacy notice</h1>
            </Container>
          </div>

          <div className="body_tnc">
            <Container>
              <h2>What Personal Information About Customers Does Superhafiz Gather?</h2>
              <p>
              The information we learn from customers helps us personalize and continually improve your Superhafiz experience. Here are the types of information we gather.
              </p>
              <b>Information You Give Us:</b>
              <p>
              We receive and store any information (voice) you give to Superhafiz. We use the information that you provide for such purposes as responding to your requests, customizing features, improving our feedback, and communicating with you. 
              </p>
              <b>We will continue to update this page to inform the customer our newest Privacy Notice policy</b>
            </Container>
          </div>
        </div>
        
      </React.Fragment>
    );
  }
}

export default PP;