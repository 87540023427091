import React, { PureComponent } from 'react';
import { Row, Col } from 'react-bootstrap';
import LazyLoad from 'react-lazyload';
import {Image, CloudinaryContext} from 'cloudinary-react';
import ReactFitText from 'react-fittext';
import './New.css';

class HomeComponent extends PureComponent{
  constructor(props){
    super(props);
    this.state = {
      images: []
    };
  }
  
  componentDidMount() {
   let url="http://localhost:3001/images"
   // check API IN DB.JSON
   fetch(url)
    .then(resp => resp.json())
    .then(data => {
      let images = data.map((image) => {
        return (          
          <Image
              publicId={image.src}
              fetch-format="auto"
              quality="auto"
              className={image.className}
              alt={image.alt}
          />
        )
      })
      this.setState({images: images});
    })
  }

    render() { 
      
      return (
        <div>
          <Row>
            <LazyLoad height={200}>
              <React.Fragment>
                <Col>
                  <CloudinaryContext cloudName="demo">
                    
                    {/*

                    i'm using array to call API by order, this.state.images[0] its mean index:"001" in db.json  */}

                    {this.state.images[0]}           {/*PLANET ORANGE*/}
                    
                    {this.state.images[1]}           {/* LEFT CLOUD  */}
                    
                    {this.state.images[2]}           {/*LEFT CLOUD BLUR*/}

                  </CloudinaryContext>
                </Col>
                <Col className="col-xs-12">
                    <ReactFitText>
                      <p className="title_home">Yuk Teman!, Jelajahi Imajinasimu Bersama</p>
                    </ReactFitText>
                    <CloudinaryContext cloudName="demo">

                      {/*

                        i'm using array to call API by order, this.state.images[3] its mean index:"003" in db.json  */}
                      
                      {this.state.images[3]}        {/*HAFIZ HOME ABSOLUTE*/}
                      
                      {this.state.images[4]}        {/*HAFIZ BALING BALING*/}
                      
                      {this.state.images[5]}        {/*  CENTER BALING  */}
                      
                      {this.state.images[6]}        {/*  NAVIGATION  */}
                      
                    </CloudinaryContext>
                </Col>
                <Col>
                    <CloudinaryContext cloudName="demo">

                      {/*

                        i'm using array to call API by order, this.state.images[3] its mean index:"003" in db.json  */}

                      {this.state.images[7]}

                      {/*OPEN THIS COMMENT IF YOU WANT TO NOT USE API*/}

                      {/* <Image
                          publicId="https://res.cloudinary.com/pthim/image/upload/v1571280062/awan_aj4bzp.png"
                          fetch-format="auto"
                          quality="auto"
                          className="right_cloud"
                      /> */}
                      {this.state.images[8]}
                      
                      {this.state.images[9]}
                    </CloudinaryContext>
                    
                </Col>
              </React.Fragment>
            </LazyLoad>
          </Row>
        </div>
      );
    }
  }
  
export default HomeComponent;
  