import React, { PureComponent } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import LazyLoad from 'react-lazyload';
import ReactFitText from 'react-fittext';
import {Image, CloudinaryContext} from 'cloudinary-react';

const ImageLoader = () => {
	return (<CloudinaryContext cloudName="demo">
	<Image
		publicId="https://res.cloudinary.com/pthim/image/upload/v1573615744/np8ocnr6xkd8pjbzpjga.webp"
		fetch-format="auto"
		quality="auto"
		className="loadingGif"
	/>
  </CloudinaryContext>)
  }
const Hafiz = () => {
	return (<LazyLoad height={200}><CloudinaryContext cloudName="demo">
	<Image
		publicId="https://res.cloudinary.com/pthim/image/upload/v1573464180/vmtl7pm9oqk4d5or6yfw.webp"
		fetch-format="auto"
		quality="auto"
		className="hafiz_contact absolute"
	/>
  </CloudinaryContext></LazyLoad>)
}
const Firework = () => {
	return (<LazyLoad height={200}><CloudinaryContext cloudName="demo">
	<Image
		publicId="https://res.cloudinary.com/pthim/image/upload/v1573464430/r18qbpfbfbfj1b7balwn.webp"
		fetch-format="auto"
		quality="auto"
		className="firework_contact absolute"
	/>
  </CloudinaryContext><CloudinaryContext cloudName="demo">
	<Image
		publicId="https://res.cloudinary.com/pthim/image/upload/v1573464430/r18qbpfbfbfj1b7balwn.webp"
		fetch-format="auto"
		quality="auto"
		className="firework_contact1 absolute"
	/>
  </CloudinaryContext></LazyLoad>)
}

class Contact extends PureComponent{
	constructor(props) {
		super(props) 
		  this.state = {
			show: false,
			isLoading : true,
			images: []
		  }
	  }
	static getDerivedStateFromProps(props, state) {
		if (props.isrender !== state.isLoading) {
		  return {isLoading: props.isrender }
		}
  
	  // Return null if the state hasn't changed
	  return null;
	}
	componentDidMount(){
	  const { isrender } = this.props
	  let url = "http://localhost:3001/images"
	  fetch(url)
		.then(resp => resp.json())
		.then(data => {
		let images = data.map((images) => {
			return (
			<Image
				publicId={images.Image}
				fetch-format="auto"
				quality="auto"
				className={images.className}
				alt={images.alt}
			/>
			)
		})
		this.setState({images: images});
	})
	  this.setState({
		isLoading : isrender
	  })
	}
	componentWillUnmount(){
		const { isrender } = this.props
	  this.setState({
		isLoading : isrender
	  })
	}

  render() {
	const { isLoading } = this.state
	if(isLoading){
		return <ImageLoader/>
	  }
    return (
    	<div className="App">
			<Router>
            <Container>
				<Row>
					<Hafiz/>
					<Firework/>
					<LazyLoad height={200}>
					<CloudinaryContext cloudName="demo">
						<Image
							publicId="https://res.cloudinary.com/pthim/image/upload/v1573464553/jy75qvdvcilui1skiit4.webp"
							fetch-format="auto"
							quality="auto"
							className="awan_contact absolute"
						/>
						{/* {this.state.images[11]} */}
				  	</CloudinaryContext>
					<ReactFitText compressor={0.9}><h3 className="title_contact absolute">THANK YOU</h3></ReactFitText>
					<Col>
					<Route>
					</Route>
						
					</Col>
					</LazyLoad>
				</Row>
            </Container>
			</Router>
    	</div>
    );
  }
}
export default Contact;
