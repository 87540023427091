import React, { PureComponent } from 'react';
import { Container } from 'react-bootstrap';
import {  Row, Col, Button } from 'react-bootstrap';
import LazyLoad from 'react-lazyload';
import {Image, CloudinaryContext} from 'cloudinary-react';
// import Sound from 'react-sound';                        , OPEN THIS COMMENT IF YOU WANT PLAY SOUND
import './New.css';

class TalkComponent extends PureComponent{
    constructor(props) {
        super(props) 
          this.state = {
            show: false,
            isLoading : true,
            images: []
          }
      }
      changeStyle()  {
        this.setState({show: !this.state.show})
      }

      componentDidMount(){
        let url = "http://localhost:3001/images"
        fetch(url)
        .then(resp => resp.json())
        .then(data => {
          let images = data.map((images) => {
            return (
              <Image
                  publicId={images.Image}
                  fetch-format="auto"
                  quality="auto"
                  className={images.className[1]}
                  alt={images.alt}
              />
            )
          })
          this.setState({images: images});
        })
      }

  render() {
    const backgroundblack = this.state.show ? "hide" : "whiteButton";
    const klikdisinistyle = this.state.show ? "hide" : "klik-style";
    const buttonclass = this.state.show ? "hide" : "talk_button";
    const buttonclassimg = this.state.show ? "hide" : "talk_button_img";
    const hafizstyle = this.state.show ? "hafiz_talk_left" : "hafiz_talk";
    const headhafizstyle = this.state.show ? "head_hafiz" : "hide";
    const headhafizstyle2 = this.state.show ? "head_hafiz2" : "hide";
    const headuserstyle = this.state.show ? "head_user" : "hide";
    const assalamualaikumstyle = this.state.show ? "assalamualaikum" : "hide";
    const walaikumsalamstyle = this.state.show ? "walaikumsalam" : "hide";
    const headuserstyle1 = this.state.show ? "head_user1" : "hide";
    const apakabarstyle = this.state.show ? "apakabar" : "hide";
    const headhafizstyle1 = this.state.show ? "head_hafiz1" : "hide";
    const kalausudahbesarstyle = this.state.show ? "kalausudahbesar" : "hide";
    const alhamdulillahstyle = this.state.show ? "alhamdulillah" : "hide";
    const headuserstyle2 = this.state.show ? "head_user2" : "hide";    
    const descriptionstyle = this.state.show ? "hide" : "description_talk";
    const apacitacitastyle = this.state.show ? "apacitacita" : "hide";
    console.log(this.state.show)

    const Before = () =>{
        return (
            <div>
                <LazyLoad height={200}>
                  <React.Fragment>
                    <Button className={buttonclass} onClick={this.changeStyle.bind(this)}>
                    <CloudinaryContext cloudName="demo">
                      <Image
                          publicId="https://res.cloudinary.com/pthim/image/upload/v1573466321/xvzkwhty61u1zjhcqgsy.webp"
                          fetch-format="auto"
                          quality="auto"
                          className={buttonclassimg}
                      />
                    </CloudinaryContext>
                    </Button>
                    <CloudinaryContext cloudName="demo">
                      <Image
                          publicId="https://res.cloudinary.com/pthim/image/upload/v1573466392/nu7mcd4gltunjbpxpip8.webp"
                          fetch-format="auto"
                          quality="auto"
                          className={backgroundblack}
                      />
                      <Image
                          publicId="https://res.cloudinary.com/pthim/image/upload/v1573466460/m2ospcftpwh4w3at6am6.webp"
                          fetch-format="auto"
                          quality="auto"
                          className={klikdisinistyle}
                      />
                    </CloudinaryContext>

                    {/*   OPEN THIS COMMENT IF YOU WANT TO USE IMAGE WITHOUT CDN      */}
                    {/* <img src={background} className={backgroundblack} alt="background-black"/> */}
                    <p className={descriptionstyle}>Untuk mengobrol dengan Super Hafiz kamu cukup menekan tombol "TALK" dan katakan Hafiz Assalamulaiakum atau Hallo Hafiz sebagai sapaan maka boneka Super Hafiz akan memberikan respons. Lalu anak-anak bisa mengajukan berbagai pertanyaan apa saja ke boneka Super Hafiz</p>
                  </React.Fragment>
                </LazyLoad>
            </div>
        )
    }
    const Awan = () =>{
      return (
        <LazyLoad height={200}>
          <React.Fragment>
            <CloudinaryContext cloudName="demo">
              <Image
                  publicId="https://res.cloudinary.com/pthim/image/upload/v1573466533/fmaxzdo5jgbk8zpsvymp.webp"
                  fetch-format="auto"
                  quality="auto"
                  className="bg_awan"
              />
              <Image
                  publicId="https://res.cloudinary.com/pthim/image/upload/v1573466613/pjqbjeeuuyeilbqvxefo.webp"
                  fetch-format="auto"
                  quality="auto"
                  className="bg_awan_tengah"
              />
            </CloudinaryContext>
          </React.Fragment>
        </LazyLoad>
      )
    }
    const Chat = () =>{
      return (
        <LazyLoad height={200}>
            <React.Fragment>
              <CloudinaryContext cloudName="demo">
                <Image
                    publicId="https://res.cloudinary.com/pthim/image/upload/v1571284715/kepala-user_wp6gt4.png"
                    fetch-format="auto"
                    quality="auto"
                    className={headuserstyle}
                />
                <Image
                    publicId="https://res.cloudinary.com/pthim/image/upload/v1571285452/assalamualaikum_ckm45s.png"
                    fetch-format="auto"
                    quality="auto"
                    className={assalamualaikumstyle}
                />
                <Image
                    publicId="https://res.cloudinary.com/pthim/image/upload/v1571285569/kepala_hafiz_acbukr.png"
                    fetch-format="auto"
                    quality="auto"
                    className={headhafizstyle}
                />
                <Image
                    publicId="https://res.cloudinary.com/pthim/image/upload/v1571285665/walaikumsalam_gj7tiz.png"
                    fetch-format="auto"
                    quality="auto"
                    className={walaikumsalamstyle}
                />
                <Image
                    publicId="https://res.cloudinary.com/pthim/image/upload/v1571284715/kepala-user_wp6gt4.png"
                    fetch-format="auto"
                    quality="auto"
                    className={headuserstyle1}
                />
                <Image
                    publicId="https://res.cloudinary.com/pthim/image/upload/v1571285796/apakabar_vkqkoc.png"
                    fetch-format="auto"
                    quality="auto"
                    className={apakabarstyle}
                />
                <Image
                    publicId="https://res.cloudinary.com/pthim/image/upload/v1571285569/kepala_hafiz_acbukr.png"
                    fetch-format="auto"
                    quality="auto"
                    className={headhafizstyle1}
                />
                <Image
                    publicId="https://res.cloudinary.com/pthim/image/upload/v1571285936/alhamdulillah_f7fqoy.png"
                    fetch-format="auto"
                    quality="auto"
                    className={alhamdulillahstyle}
                />
                <Image
                    publicId="https://res.cloudinary.com/pthim/image/upload/v1571284715/kepala-user_wp6gt4.png"
                    fetch-format="auto"
                    quality="auto"
                    className={headuserstyle2}
                />
                <Image
                    publicId="https://res.cloudinary.com/pthim/image/upload/v1571286159/apacitacita_skomnz.png"
                    fetch-format="auto"
                    quality="auto"
                    className={apacitacitastyle}
                />
                <Image
                    publicId="https://res.cloudinary.com/pthim/image/upload/v1571285569/kepala_hafiz_acbukr.png"
                    fetch-format="auto"
                    quality="auto"
                    className={headhafizstyle2}
                />
                <Image
                    publicId="https://res.cloudinary.com/pthim/image/upload/v1571286282/kalausudahbesar_hfk3x5.png"
                    fetch-format="auto"
                    quality="auto"
                    className={kalausudahbesarstyle}
                />
              </CloudinaryContext>
            </React.Fragment>
        </LazyLoad>
      )
    }
    return (
      <Container>
          <Before/>
        <Row>
        <LazyLoad height={200}>

          {/*OPEN THIS COMMENT IF YOU WANT TO PLAY SOUND*/}

          {/* <Sound
            url="./withoutuservoice.mp3"
            playStatus={Sound.status.PLAYING}
            playFromPosition={200}
            volume={100}
            autoLoad={false}
            loop={false}
            /> */}
          <Col>
          </Col>
          <Col>
              <LazyLoad height={200}>
                <React.Fragment>
                  <CloudinaryContext cloudName="demo">
                    <Image
                      publicId="https://res.cloudinary.com/pthim/image/upload/v1573466852/ysowjpxpiydsp88hjx42.webp"
                      fetch-format="auto"
                      quality="auto"
                      className={hafizstyle}
                    />
                    {/* {this.state.images[41]} */}
                  </CloudinaryContext>
                  {/* <img src={hafiz} className={hafizstyle} alt="planet ungu di halaman talk"/>        */}
                </React.Fragment>
              </LazyLoad>
          </Col>
          <Col className="column">
            <Chat/>    
          </Col>
          </LazyLoad>
        </Row>
        <Row className="bg_awan_talk">
          <Awan/>
        </Row>
      </Container>
    );
  }
}
export default TalkComponent;
