import React, { PureComponent } from 'react';
import { Button } from 'react-bootstrap';
import { Carousel } from "react-responsive-carousel"; // TO MAKE SLIDE CAROUSEL, LOCATION ON NODE MODULE
import "react-responsive-carousel/lib/styles/carousel.min.css"; // STYLE FOR SLIDE CAROUSEL , LOCATION ON NODE MODULE
import { BrowserRouter as Router, Route, Link } from 'react-router-dom'; // REACT ROUTER DOM FOR NAVIGATION

import Home from './Home';                      // PAGE HOME IN SLIDE NUMBER 1
import About from './About';                    // PAGE ABOUT IN SLIDE NUMBER 2
import Features from './Features';              // PAGE FEATURES IN SLIDE NUMBER 3
import FeaturesSecond from './FeaturesSecond';  // PAGE FEATURES SECOND IN SLIDE NUMBER 4
import Talk from './Talk';                      // PAGE FEATURES IN SLIDE NUMBER 5
import Contact from './Contact';                // PAGE CONTACT IN SLIDE NUMBER 6
import TNC from './TNC';                        // PAGE TERM OF USE
import PP from './PP';                          // PAGE PRIVACY NOTICE
import CU from './CU';                          // PAGE CONTACT US
  
import ParticlesPage from './ParticlesPage';    // THIS PAGE WILL MAKE PARTICLE EFFECT, USE IT IF YOU NEED
// import Counting from './Counting';           //this is COUNTING PAGE, active it if you need
// import AliceCarousel from 'react-alice-carousel';
// import "react-alice-carousel/lib/alice-carousel.css";

import './New.css';                             // YOU CAN CUSTOM OR EDIT CSS ON HERE


// * use PureComponent to make this website more fast
// * stateLoading : true , its mean that page will display none
// * stateLoading : false , its mean that page will show and component on that page will be render too

class App extends PureComponent {
  state = {
    // countingLoading : true,                 // open this comment if you will show COUNTING PAGE
    homeLoading : true,
    aboutLoading : true,
    featuresLoading :true,
    featuresSecondLoading :true,
    talkLoading : true,
    contactLoading : true,
    selectedItem : 0    
  }
  onChangeCarousel = (selectedItem) => {
    this.setState({
      selectedItem
    })
    // if(selectedItem === 0){
    //   console.log('select counting')
    //   this.setState({
    //     homeLoading : true,
    //     aboutLoading : true,               // open this comment if you will show COUNTING PAGE and change selectedItem number as in order
    //     featuresLoading :true,
    //     featuresSecondLoading : true,
    //     talkLoading : true,
    //     contactLoading : true,
    //   })
    // }
    if(selectedItem === 0){
      console.log('slide home')               // CHECK CONSOLE.LOG, IF SLIDE HOME ACTIVE YOU WILL GET "slide home"
      this.setState({
        // countingLoading : true,
        homeLoading : true,
        aboutLoading : true,                  
        featuresLoading :true,
        featuresSecondLoading : true,
        talkLoading : true,
        contactLoading : true,
      })
    }
    else if(selectedItem === 1){
      console.log('slide about')              // CHECK CONSOLE.LOG, IF SLIDE ABOUT ACTIVE YOU WILL GET "slide about"
      this.setState({
        // countingLoading : true,
        homeLoading : true,
        aboutLoading : false,         
        featuresLoading :true,
        featuresSecondLoading : true,
        talkLoading : true,
        contactLoading : true,
      })
    }
    else if(selectedItem === 2){
      console.log('slide features')           // CHECK CONSOLE.LOG, IF SLIDE FEATURES ACTIVE YOU WILL GET "slide features"
      this.setState({
        // countingLoading : true,
        homeLoading : true,
        aboutLoading : true,
        featuresLoading : false,
        featuresSecondLoading : true,
        talkLoading : true,
        contactLoading : true,
      })
    }
    else if(selectedItem === 3){
      console.log('slide features second')    // CHECK CONSOLE.LOG, IF SLIDE FEATURES ACTIVE YOU WILL GET "slide features second"
      this.setState({
        countingLoading : true,
        homeLoading : true,
        aboutLoading : true,
        featuresLoading : true,
        featuresSecondLoading : false,
        talkLoading : true,
        contactLoading : true,
      })
    }
    else if(selectedItem === 4){            
      console.log('slide TALK')               // CHECK CONSOLE.LOG, IF SLIDE TALK ACTIVE YOU WILL GET "slide TALK"
      this.setState({
        countingLoading : true,
        homeLoading : true,
        aboutLoading : true,
        featuresLoading : true,
        featuresSecondLoading : true,
        talkLoading : false,
        contactLoading : true,
      })
    }
    else if(selectedItem === 5){
      console.log('slide contact')          // CHECK CONSOLE.LOG, IF SLIDE CONTACT ACTIVE YOU WILL GET "slide contact"
      this.setState({
        countingLoading : true,
        homeLoading : true,
        aboutLoading : true,
        featuresLoading : true,
        featuresSecondLoading : true,
        talkLoading : true,
        contactLoading : false,
      })
    }
  }
  render(){ 
    const { talkLoading , contactLoading, aboutLoading, featuresLoading, featuresSecondLoading, selectedItem } = this.state
    return (
      <Router>
        
        <div>
           <ParticlesPage></ParticlesPage>

      {/*

        change from true to false if you want disable some feature in carousel/slide,
        example : if you want disable arrows slide you can change showArrows={true}  TO showArrows={true}
        check this source to the detail https://www.npmjs.com/package/react-responsive-carousel

      */}

          <Carousel showArrows={true} showIndicators={true} showStatus={false} showThumbs={false} swipeScrollTolerance={20} width='100%' useKeyboardArrows={true} transitionTime={200} infiniteLoop={true}
          onChange={this.onChangeCarousel.bind(this)} selectedItem = {selectedItem} swipeable={true} thumbWidth ={200} emulateTouch={true} autoPlay={false}
          >        
            {/* <div>
              <div className="backgroundCounting absolute">               // OPEN THIS COMMENT IF YOU WANT SHOW COUNTING PAGE IN SLIDE
                <Counting/>
              </div>
            </div> */}
            <div>      
              <div className="backgroundLayout absolute">
              <Home/> 
              </div>
            </div>
            <div>
              <div className="backgroundAbout absolute">
                <About isrender = { aboutLoading } />                   {/* // isrender is props from page */}
              </div>
            </div>
            <div>
              <div className="backgroundFeatures absolute">
                <Features isrender = { featuresLoading } />
              </div>
            </div>
            <div>
              <div className="backgroundFeatures absolute">
                <FeaturesSecond isrender = { featuresSecondLoading } />
              </div>
            </div>
            <div>
              <div className="backgroundTalk1 absolute">
              <Talk isrender = { talkLoading } />
              </div>
            </div>
            <div>
              <div className="backgroundContact absolute">
                  <Contact isrender = { contactLoading }/>
                  <Link to={'/TNC'} className="nav-link" ><Button className="TNC_button absolute">Term of Use</Button></Link>
                  <Link to={'/PP'} className="nav-link" target="_blank"><Button className="PP_button absolute">Privacy Notice</Button></Link>
                  <Link to={'/CU'} className="nav-link" target="_blank"><Button className="CU_button absolute">Contact Us</Button></Link>
              </div>
            </div>
      
          </Carousel>

          <Route path='/TNC' component={TNC} />
          <Route path='/PP' component={PP} />
          <Route path='/CU' component={CU} />
        </div>
      </Router>
    );
  }
}
export default App;
