import React, { PureComponent } from 'react';
import './New.css';
import Loadable from 'react-loadable';
import Loading from './TalkComponent';
import {Image, CloudinaryContext} from 'cloudinary-react';

const ImageLoader = () => {
  return (<CloudinaryContext cloudName="demo">
	<Image
		publicId="https://res.cloudinary.com/pthim/image/upload/v1573615744/np8ocnr6xkd8pjbzpjga.webp"
		fetch-format="auto"
		quality="auto"
		className="loadingGif"
	/>
  </CloudinaryContext>)
}
const LoadableComponent = Loadable({
  loader: () => import('./TalkComponent'),
  loading: Loading,
  delay: 20000
});

class Talk extends PureComponent{
  constructor(props) {
    super(props) 
      this.state = {
        // show: false,
        isLoading : true,
      }
  }
  static getDerivedStateFromProps(props, state) {
      if (props.isrender !== state.isLoading) {
        return {isLoading: props.isrender }
      }

    // Return null if the state hasn't changed
    return null;
  }
  componentDidMount(){
    const { isrender } = this.props
    this.setState({
      isLoading : isrender
    })
  }
  
  // changeStyle()  {
  //   this.setState({show: !this.state.show})
  // }

  render() {
    const { isLoading } = this.state
    // const playStatusSound = this.state.show ? "Sound.status.PLAYING" : "Sound.status.STOPPED"
    if(isLoading){
      return <ImageLoader/>
    }
    // console.log(playStatusSound)
    return (
      <LoadableComponent/>
    );
  }
}
export default Talk;