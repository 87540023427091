import React, { PureComponent } from 'react';
// import './App.css';
import { Container } from 'react-bootstrap';

class CU extends PureComponent {
    render() {
        return ( <React.Fragment>
            <div className= "backgroundTNC absolute">
                <div className= "Header_tnc">
                    <Container>
                        <h1 className="title_TNC"> Contact Us </h1> 
                    </Container> 
                </div> 
                <div className="body_tnc">
                    <Container>
                        <p> Office: 021 - 75685326 Service Center: 021 - 75685327 </p> 
                        <p> Website: www.alqolam.com </p> 
                    </Container> 
                </div> 
                <div className="footer">
                    <p> Version 1.0.5  </p> 
                </div> 
            </div> 
            </React.Fragment>
        );
    }
}

export default CU;
