import React, { PureComponent } from 'react';
import { Container } from 'react-bootstrap';
import Loadable from 'react-loadable';
import Loading from './HomeComponent';                      // HOME COMPONENT IN HERE
import {Image, CloudinaryContext} from 'cloudinary-react';

import './New.css';                                         // CUSTOM CSS I HERE

const ImageLoader = () => {
	return (<CloudinaryContext cloudName="demo">
	<Image
		publicId="https://res.cloudinary.com/pthim/image/upload/v1573615744/np8ocnr6xkd8pjbzpjga.webp"
		fetch-format="auto"
		quality="auto"
		className="loadingGif"
	/>
  </CloudinaryContext>)
  }
const LoadableComponent = Loadable({
  loader: () => import('./HomeComponent'),
  loading: Loading,
});
class Home extends PureComponent{
  constructor(props) {
    super(props) 
      this.state = {
        isLoading : true,
      }
  }
  static getDerivedStateFromProps(props, state) {
    if (props.isrender !== state.isLoading) {
      return {isLoading: props.isrender }
    }
    // Return null if the state hasn't changed
    return null;
  }
  componentDidMount(){
    const { isrender } = this.props
    this.setState({
      isLoading : isrender
    })
  }

  render() {
    const { isLoading } = this.state
    if(isLoading){
      return <ImageLoader/>
    }
    return (
      <div>
        <Container>
        <LoadableComponent/>
        </Container>
      </div>
    );
  }
}

export default Home;