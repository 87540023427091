import React, { PureComponent } from 'react';
import {Image, CloudinaryContext} from 'cloudinary-react';
import { Row, Col } from 'react-bootstrap';
import LazyLoad from 'react-lazyload';
import './New.css';
import ReactFitText from 'react-fittext';

class FeaturesComponent extends PureComponent{
  constructor(props){
    super(props);
    this.state = {
      images: []
    };
  }

  componentDidMount() {
    let url="http://localhost:3001/images"
    fetch(url)
     .then(resp => resp.json())
     .then(data => {
       let images = data.map((image) => {
         return (          
           <Image
               publicId={image.src}
               fetch-format="auto"
               quality="auto"
               className={image.className}
               alt={image.alt}
           />
         )
       })
       this.setState({images: images});
     })
   }

  render() { 
    return (
      <div>
        <Row className="detail_product">

          {/*USE REACT FRAGMENT FOR A COMPONENT TO RETURN MULTIPLE ELEMENT*/}
          <React.Fragment>
            <h2 className="title_features">Detail Produk</h2>
            <hr className="hr_features"/>
            <CloudinaryContext cloudName="demo">
              {this.state.images[32]}            {/*      INDEX 032 ,awan mobile       */}
            </CloudinaryContext>          
          </React.Fragment>

          <React.Fragment>
            <Col lg={4} className="hide-mobile">
              <LazyLoad height={200}>
                <React.Fragment>
                  <CloudinaryContext cloudName="demo">
                    {this.state.images[25]}
                    {this.state.images[31]}
                  </CloudinaryContext>

                  {/*OPEN THIS COMMENT IF YOU WON'T USE CDN AND API*/}
                  {/* <img src={bg} className="bg-detail absolute" alt="fire roket kiri"/> */}
                  <h3 className="mata-judul-Detail absolute">Mata</h3>
                    <ReactFitText>
                      <p className="Detail-mata absolute">
                        Super Hafiz dilengkapi mata dengan ekspresi layaknya manusia.
                        Ketika dinyalakan matanya akan terus berkedip-kedip dan setiap
                        berinteraksi akan mengeluarkan ekspresi seperti bahagia, sedih,
                        marah, dan icon-icon yang menyesuaikan konten.
                      </p>
                    </ReactFitText>
                </React.Fragment>
              </LazyLoad>
            </Col>

            <Col lg={4} className="hide-mobile">
              <LazyLoad height={200}>
                <CloudinaryContext cloudName="demo">
                  {this.state.images[26]}
                  {this.state.images[31]}
                </CloudinaryContext>
                <h3 className="material-judul-Detail absolute">Bahan</h3>
                
                <p className="Detail-material absolute">
                  Super Hafiz dirancang dan dibuat dari material-material pilihan
                  yang berkualitas dan kokoh, serta menggunakan cat yang aman untuk
                  dimainkan oleh anak-anak.
                </p>
              </LazyLoad>
            </Col>

            <Col lg={4} className="hide-mobile">
              <LazyLoad height={200}>
                <CloudinaryContext cloudName="demo">
                  {this.state.images[27]}
                  {this.state.images[31]}
                </CloudinaryContext>
                <h3 className="tombol-judul-Detail absolute">Tombol</h3>
                <p className="Detail-tombol absolute">
                  Super Hafiz juga dilengkapi tombol untuk memudahkan anak bermain
                  secara online dan offline. Tombol-tombol ini disusun dengan bentuk
                  yang melingkar dan menyesuaikan postur jari anak-anak sehingga
                  mudah digunakan.
                </p>
              </LazyLoad>
            </Col>

          </React.Fragment>
          <React.Fragment>

            <Col sm={12} className="hide-desktop">
                <CloudinaryContext cloudName="demo">
                  {this.state.images[28]}
                </CloudinaryContext>
            </Col>

            <Col sm={12} className="hide-desktop">
                <CloudinaryContext cloudName="demo">
                  {this.state.images[29]} 
                </CloudinaryContext>
            </Col>

            <Col sm={12} className="hide-desktop">
                <CloudinaryContext cloudName="demo">
                {this.state.images[30]}
                </CloudinaryContext>
            </Col>
            
          </React.Fragment>
        </Row>
      </div>
    );
  }
}

export default FeaturesComponent;
