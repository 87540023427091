import React, { PureComponent } from 'react';
import {  Row } from 'react-bootstrap';
import LazyLoad from 'react-lazyload';
import ReactFitText from 'react-fittext';
import {Image, CloudinaryContext} from 'cloudinary-react';
import './New.css';

class AboutComponent extends PureComponent{
    constructor(props){
        super(props);
        this.state = {
          images: []
        };
      }
    
    componentDidMount() {
    let url="http://localhost:3001/images"
    fetch(url)
        .then(resp => resp.json())
        .then(data => {
        let images = data.map((image) => {
            return (          
            <Image
                publicId={image.src}
                fetch-format="auto"
                quality="auto"
                className={image.className}
                alt={image.alt}
            />
            )
        })
        this.setState({images: images});
        })
    }

	render() {
    	return (
    	<div>
            <LazyLoad height={200}>
			<Row>
				<React.Fragment>
  				<div className="cube absolute"></div>
  				<div className="cube absolute version1"></div>
  				<div className="cube absolute"></div>
				    <CloudinaryContext cloudName="demo">
              {this.state.images[10]}           {/*    bi     */}
            
              {this.state.images[11]}           {/*    bi2    */}
            
              {this.state.images[12]}           {/*LAYOUT ABOUT*/}
            
              {this.state.images[13]}           {/* WIFI ABOUT */}
	  
              {this.state.images[14]}           {/* TALK ABOUT */}
		
              {this.state.images[15]}           {/* ONLINE CONTENT ABOUT */}
		
              {this.state.images[16]}           {/* OFFLNE CONTENT ABOUT */}
		
              {this.state.images[17]}           {/* BILLINGUAL ABOUT */}
		
              {this.state.images[18]}           {/* KONTEN ISLAMI ABOUT */}
		
              {this.state.images[19]}           {/* AI ABOUT */}
		
              {this.state.images[20]}           {/* SONG ABOUT */}
		
              {this.state.images[21]}           {/* ALQURAN ABOUT */}
		
              {this.state.images[22]}           {/* FUN EMOTICON ABOUT */}
		
              {this.state.images[23]}           {/* ENSIKLOPEDIA ABOUT */}
		
              {this.state.images[24]}           {/* MATDAS ABOUT */}           

              {/*OPEN THIS COMMENT IF YOU WANT TO USE JUST CDN WITHOUT API*/}

		          {/* <Image
                publicId="https://res.cloudinary.com/pthim/image/upload/v1571283291/about_matdasmobile_mqzsi9.png"
                fetch-format="auto"
                quality="auto"
                className="matdas_about absolute"
            	/>       */}
          </CloudinaryContext>
				
    				<div className="background_about"></div>
    				<h1 className="title_about absolute">Cerdas & Shaleh Bersama Super Hafiz</h1>
    				<hr className="hr_title"/>
    				<ReactFitText>
    					<h6 className="description absolute">
    						Super Hafiz merupakan mainan edukasi islami dengan teknologi berbasis kecerdasan buatan atau artificial intelligence (AI) yang di rancang untuk berinteraksi dengan anak-anak sebagai teman belajar, ngobrol, dan bermain.
    						Di dalamnya terdapat konten islami seperti murotal alquran, cerita islami, lagu islami, ensiclopedia islam, ibadah, ngobrol, hingga konten pengetahuan umum lengkap.
    					</h6>
				    </ReactFitText>
			   	</React.Fragment>
			   </Row>
       </LazyLoad>
    	</div>
    );
  }
}
export default AboutComponent;