import React, { PureComponent } from 'react';
import './App.css';
import { Container} from 'react-bootstrap';

class TNC extends PureComponent{
  render() {
    return (
      <React.Fragment>
      <div className="backgroundTNC absolute">
          <div className="Header_tnc">
            <Container>
              <h1 className="title_TNC">Term of Use</h1>
            </Container>
          </div>
          <div className="body_tnc">
            <Container>
              {/* <h2>Limitations Of Liability</h2> */}
              <p>
              This is an agreement between you and PT. Hidayah Insan Mulia (with its affiliates, "Al-Qolam" or "We"). Before using Superhafiz, please read these Superhafiz Terms of Use, 
              </p>
              <b>For the purpose of these Superhafiz Terms of Use:</b>
              <p>
              "Superhafiz" means Superhafiz voice services, which includes Third Party Services, digital content & Software
              </p>
              {/* <b>Log Data</b> */}
              <p>
              “Superhafiz” means all information related to your use of Superhafiz Products, including your voice and other inputs, responses provided to you through Superhafiz, information we receive in connection with Third Party Services and Auxiliary Products you use
              </p>
              <p>"Third Party Service" means any service or application provided by a third party that we make available to you for use on or through Superhafiz.</p>
              <b>We will continue to update this page to inform the customer our newest Term of Use policy</b>
            </Container>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default TNC;