import React, { PureComponent } from 'react';
import { Row, Col } from 'react-bootstrap';
import LazyLoad from 'react-lazyload';
import ReactFitText from 'react-fittext';
import {Image, CloudinaryContext} from 'cloudinary-react';
import './New.css';

class FeaturesSecondComponent extends PureComponent{
  constructor(props){
    super(props);
    this.state = {
      images: []
    };
  }

  componentDidMount() {
    let url="http://localhost:3001/images"
    fetch(url)
     .then(resp => resp.json())
     .then(data => {
       let images = data.map((image) => {
         return (          
           <Image
               publicId={image.src}
               fetch-format="auto"
               quality="auto"
               className={image.className}
               alt={image.alt}
           />
         )
       })
       this.setState({images: images});
     })
  }

  render() { 
    return (
      <div>
        <Row className="detail_product">
          <React.Fragment>
            <h2 className="title_features">Detail Produk</h2>
            <hr className="hr_features"/>
            <CloudinaryContext cloudName="demo">
              {this.state.images[32]}
            </CloudinaryContext>
          </React.Fragment>
            {/* ======== Tampilan di desktop ========= */}
            <React.Fragment>
            <Col lg={4} className="hide-mobile">
              <LazyLoad height={200}>
                <CloudinaryContext cloudName="demo">
                  {/*

                    i'm using array to call API by order, this.state.images[33] its mean index:"034" in db.json  */}

                  {this.state.images[33]}         {/*SPEAKER*/}
                  {this.state.images[31]}         {/*BG-DETAIL*/}

                  {/*  OPEN THIS COMMENT IF YOU WON'T USE API AND JUST CDN  */}

                  {/* <Image
                      publicId="https://res.cloudinary.com/pthim/image/upload/v1571384912/ygzloblirs3ichl9s2ag.png"
                      fetch-format="auto"
                      quality="auto"
                      className="speaker absolute"
                  /> */}
                </CloudinaryContext>
                {/*  OPEN THIS COMMENT IF YOU WON'T USE API AND USE CDN  */}

                {/* <img src={speaker} className="speaker absolute" alt="fire roket kiri"/> */}
                <h3 className="speaker-judul-Detail absolute">Speaker</h3>
                <ReactFitText>
                  <p className="Detail-speaker absolute">
                    Untuk mendukung output suara yang bagus Super Hafiz
                    dilengkapi speaker yang berkualitas. Sehingga suara
                    yang keluar terdengar jernih dan jelas.
                  </p>
                </ReactFitText>

              </LazyLoad>
            </Col>
            <Col lg={4} className="hide-mobile">
              <LazyLoad height={200}>
                <React.Fragment>
                  <CloudinaryContext cloudName="demo">
                    {this.state.images[34]}       {/*  KAKI  */}
                    {this.state.images[31]}       {/*BG-DETAIL*/}

                    </CloudinaryContext>
                    <h3 className="kaki-judul-Detail absolute">Anti Slip</h3>
                    <ReactFitText>
                      <p className="Detail-kaki absolute">
                        Super Hafiz dilengkapi karet anti slip untuk memberikan
                        pengamanan pertama ketika anak-anak bermain, sehingga Super Hafiz tidak mudah jatuh dan lecet.
                      </p>
                    </ReactFitText>
                </React.Fragment>
              </LazyLoad>
            </Col>
            <Col lg={4} className="hide-mobile">
              <LazyLoad height={200}>
                <React.Fragment>
                    <CloudinaryContext cloudName="demo">
                      {this.state.images[35]}     {/*  TOMBOL BELAKANG  */}
                      {this.state.images[31]}       {/*BG-DETAIL*/}
                    </CloudinaryContext>
                    <h3 className="tombolbelakang-judul-Detail absolute">Tombol Belakang</h3>
                    <ReactFitText>
                      <p className="Detail-tombolbelakang absolute">
                        Super Hafiz dilengkapi tombol power yang unik dengan
                        sistem putar, sehingga anak dengan mudah menggunakannya.
                        Tombol ini juga berfungsi untuk memperbesar dan mengecilkan
                        volume suara.
                      </p>
                    </ReactFitText>
                </React.Fragment>
              </LazyLoad>
            </Col>
            </React.Fragment>
            {/* ======== End Tampilan di desktop ========= */}
            
            {/* ======== Tampilan di Mobile ========= */}
            <React.Fragment>

              <Col sm={12} className="hide-desktop">
                <CloudinaryContext cloudName="demo">
                  {this.state.images[36]}
                </CloudinaryContext>
              </Col>

              <Col sm={12} className="hide-desktop">
                <CloudinaryContext cloudName="demo">
                  {this.state.images[37]}
                </CloudinaryContext>
              </Col>

              <Col sm={12} className="hide-desktop">
                <CloudinaryContext cloudName="demo">
                  {this.state.images[38]}
                </CloudinaryContext>
              </Col>
            </React.Fragment>
            {/* ======== Akhir Tampilan di Mobile ========= */}

        </Row>
      </div>
    );
  }
}

export default FeaturesSecondComponent;